<template>
  <div v-if="getCurrentOptions" class="product-detail-wrap">
    <div class="back-to-list-text cus-is-button" @click="checkSave()">
      <el-icon style="margin-right: 18px"><Back /></el-icon>
      返回列表
    </div>
    <p class="title">{{ getCurrentName }}</p>
    <el-tabs v-model="activeName" style="margin-top: 30px; position: relative">
      <el-tab-pane label="口味預設值設定" name="taste">
        <el-form
          :model="ruleForm"
          status-icon
          hide-required-asterisk
          class="taste-form"
          label-position="left"
          label-width="120px"
          size="large"
        >
          <el-form-item v-for="(item, index) in taste" :key="index">
            <template #label>
              <span class="label">{{ item.label }}</span>
            </template>
            <div v-if="getCurrentOptions[item.array].length > 0">
              <el-radio-group v-if="item.value !== 'add_on'" v-model="ruleForm[item.value]">
                <el-radio
                  v-for="(option, o_index) in getCurrentOptions[item.array]"
                  :key="o_index"
                  :label="option.id"
                  size="large"
                  @click.prevent="handleClickRadio(item.value, option.id)"
                >
                  {{ option.name }}
                </el-radio>
              </el-radio-group>
              <el-checkbox-group v-else v-model="ruleForm[item.value]" :disabled="!ruleForm.size">
                <el-checkbox
                  v-for="(option, o_index) in getCurrentOptions[item.array]"
                  :key="o_index"
                  :label="option.id"
                  size="large"
                  :name="item.value"
                >
                  {{ option.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <p v-else class="empty">無法選擇</p>
          </el-form-item>
        </el-form>
        <div class="button-wrap">
          <el-button type="info" plain style="font-weight: normal; border: none; width: 100px" @click="clearOptions">
            清除
          </el-button>
          <el-button type="primary" style="width: 100px" @click="handleSave">儲存</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { ref, watch, reactive, getCurrentInstance } from 'vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'product-detail',
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const activeName = ref('taste');
    //取得內容
    const { getCurrentOptions, getCurrentDefault, getCurrentName } = useGetters('default', [
      'getCurrentOptions',
      'getCurrentDefault',
      'getCurrentName',
    ]);
    const CLEAR_CURRENT_OPTIONS = useMutations('default', ['CLEAR_CURRENT_OPTIONS']);
    if (!getCurrentOptions.value) router.push('/product/default?page=1');
    watch(route, () => {
      if (!route.fullPath.includes('/detail')) CLEAR_CURRENT_OPTIONS();
    });
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(JSON.parse(JSON.stringify(getCurrentDefault.value || {})));
    const taste = reactive([
      { label: '容量', array: 'size', value: 'size' },
      { label: '冷熱', array: 'temperature', value: 'temperature' },
      { label: '甜度', array: 'sweetness', value: 'sweetness' },
    ]);
    //radio選擇
    const handleClickRadio = (type, value) => {
      if (ruleForm.value[type] === value) {
        ruleForm.value[type] = '';
      } else ruleForm.value[type] = value;
    };
    //清除
    const clearOptions = () => {
      ruleForm.value.size = '';
      ruleForm.value.temperature = '';
      ruleForm.value.sweetness = '';
    };
    //檢查未儲存
    const getDefaultIsChange = useGetters('default', ['getDefaultIsChange']);
    const SET_IS_CHANGE = useMutations('default', ['SET_IS_CHANGE']);
    watch(
      ruleForm.value,
      () => {
        if (JSON.stringify(ruleForm.value) !== JSON.stringify(getCurrentDefault.value)) SET_IS_CHANGE(true);
        else SET_IS_CHANGE(false);
      },
      { deep: true }
    );
    const checkSave = (next) => {
      if (getDefaultIsChange.value) {
        proxy
          .$confirm(`變更尚未儲存，確定要離開此頁？`, '尚未儲存', {
            confirmButtonText: '離開',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            if (next) next();
            else router.go(-1);
          })
          .catch((e) => e);
      } else {
        if (next) next();
        else router.go(-1);
      }
    };
    onBeforeRouteLeave((to, from, next) => checkSave(next));
    //儲存
    const doSaveProductDefault = useActions('default', ['doSaveProductDefault']);
    const handleSave = async () => {
      const options = JSON.parse(JSON.stringify(getCurrentOptions.value));
      options.size.forEach((e) => {
        if (e.id === ruleForm.value.size) e.default = true;
        else e.default = false;
      });
      options.temperature.forEach((e) => {
        if (e.id === ruleForm.value.temperature) e.default = true;
        else e.default = false;
      });
      options.sweetness.forEach((e) => {
        if (e.id === ruleForm.value.sweetness) e.default = true;
        else e.default = false;
      });
      await doSaveProductDefault({ options, default_value: ruleForm.value });
      proxy.$message({
        type: 'success',
        message: `已儲存變更`,
      });
      router.go(-1);
    };
    return {
      activeName,
      getCurrentOptions,
      getCurrentName,
      ruleFormRef,
      ruleForm,
      taste,
      handleClickRadio,
      clearOptions,
      checkSave,
      handleSave,
    };
  },
};
</script>
<style lang="scss" scoped>
.product-detail-wrap {
  width: 100%;
  height: fit-content;
  //   min-height: 500px;
  position: relative;
  overflow: scroll;
  @include white-bg;
  padding: 22px;
}
.title {
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.button-wrap {
  position: absolute;
  bottom: 0;
  right: 20px;
}
.taste-form {
  margin: 30px 0 60px;
  .el-form-item {
    margin-bottom: 0;
  }
  .label {
    color: $color-text;
    font-weight: 500;
  }
}
.empty {
  color: $color-line;
}
</style>
